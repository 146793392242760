import React from "react";
import HeaderWithoutBanner from "../../organisms/HeaderWithoutBanner";
import ProductsMain from "../../organisms/ProductsMain";
import MediaQuery from "react-responsive";
import { TwitterShareButton,TwitterIcon,FacebookShareButton,FacebookIcon} from 'react-share';
import "react-image-gallery/styles/css/image-gallery.css";
import ReactGA from "react-ga4";

const Date: React.FC = () => {
  React.useEffect(()=>{
    // scriptを読み込み
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(script);
    // アンマウント時に一応scriptタグを消しておく
    return () => {
      document.body.removeChild(script);
    }
  }, [])
  ReactGA.initialize("G-XBRWCGVCPT");
  ReactGA.send("pageview");
  const articleUrl = "https://inoue-mokei.com/products/date";
  const productImage = () => {
    return (
      <div className="product-img">
        <p>
          <img src="/img/products/c98/poster.jpeg" alt="デートのポスター" />
        </p>
      </div>
    )};

  const productText = () =>{
    return(
      <div className="product-txt-container">
        <div className="product-txt-container-header">
          <span className="products-tag">C98(エアコミケ)</span>
          <span className="products-genre">漫画</span>
          <span className="products-item-title">デート</span>
        </div>
        <div className="content">
          <p className="catchcopy">きっとね。</p>
          <p className="summary">
            作家の道具小路氏とイノウエ模型のコラボ作品。
          </p>
          <p className="up-date">発表日: 2020.04.29 </p>
          <div className="twitter-wrap">
            <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">デート[1/5] <a href="https://twitter.com/hashtag/%E7%8A%AC?src=hash&amp;ref_src=twsrc%5Etfw">#犬</a> <a href="https://twitter.com/hashtag/C98?src=hash&amp;ref_src=twsrc%5Etfw">#C98</a> <a href="https://twitter.com/hashtag/%E3%82%A8%E3%82%A2%E3%82%B3%E3%83%9F%E3%82%B1?src=hash&amp;ref_src=twsrc%5Etfw">#エアコミケ</a> <a href="https://twitter.com/hashtag/%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB?src=hash&amp;ref_src=twsrc%5Etfw">#サークル</a> <a href="https://t.co/lBWeaPXhMy">pic.twitter.com/lBWeaPXhMy</a></p>&mdash; イノウエ模型@C99木曜東マ12b (@inoue_mokei) <a href="https://twitter.com/inoue_mokei/status/1255330991360610306?ref_src=twsrc%5Etfw">April 29, 2020</a></blockquote>
            <blockquote className="twitter-tweet" data-conversation="none"><p lang="ja" dir="ltr">デート[2/5] <a href="https://twitter.com/hashtag/%E7%8A%AC?src=hash&amp;ref_src=twsrc%5Etfw">#犬</a> <a href="https://twitter.com/hashtag/C98?src=hash&amp;ref_src=twsrc%5Etfw">#C98</a> <a href="https://twitter.com/hashtag/%E3%82%A8%E3%82%A2%E3%82%B3%E3%83%9F%E3%82%B1?src=hash&amp;ref_src=twsrc%5Etfw">#エアコミケ</a> <a href="https://twitter.com/hashtag/%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB?src=hash&amp;ref_src=twsrc%5Etfw">#サークル</a> <a href="https://t.co/Trh0gLH3G2">pic.twitter.com/Trh0gLH3G2</a></p>&mdash; イノウエ模型@C99木曜東マ12b (@inoue_mokei) <a href="https://twitter.com/inoue_mokei/status/1255331204255154176?ref_src=twsrc%5Etfw">April 29, 2020</a></blockquote>
            <blockquote className="twitter-tweet" data-conversation="none"><p lang="ja" dir="ltr">デート[3/5] <a href="https://twitter.com/hashtag/%E7%8A%AC?src=hash&amp;ref_src=twsrc%5Etfw">#犬</a> <a href="https://twitter.com/hashtag/C98?src=hash&amp;ref_src=twsrc%5Etfw">#C98</a> <a href="https://twitter.com/hashtag/%E3%82%A8%E3%82%A2%E3%82%B3%E3%83%9F%E3%82%B1?src=hash&amp;ref_src=twsrc%5Etfw">#エアコミケ</a> <a href="https://twitter.com/hashtag/%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB?src=hash&amp;ref_src=twsrc%5Etfw">#サークル</a> <a href="https://t.co/hudguvcvED">pic.twitter.com/hudguvcvED</a></p>&mdash; イノウエ模型@C99木曜東マ12b (@inoue_mokei) <a href="https://twitter.com/inoue_mokei/status/1255331411491540993?ref_src=twsrc%5Etfw">April 29, 2020</a></blockquote>
            <blockquote className="twitter-tweet" data-conversation="none"><p lang="ja" dir="ltr">デート[4/5] <a href="https://twitter.com/hashtag/%E7%8A%AC?src=hash&amp;ref_src=twsrc%5Etfw">#犬</a> <a href="https://twitter.com/hashtag/C98?src=hash&amp;ref_src=twsrc%5Etfw">#C98</a> <a href="https://twitter.com/hashtag/%E3%82%A8%E3%82%A2%E3%82%B3%E3%83%9F%E3%82%B1?src=hash&amp;ref_src=twsrc%5Etfw">#エアコミケ</a> <a href="https://twitter.com/hashtag/%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB?src=hash&amp;ref_src=twsrc%5Etfw">#サークル</a> <a href="https://t.co/a3L8eVxh5q">pic.twitter.com/a3L8eVxh5q</a></p>&mdash; イノウエ模型@C99木曜東マ12b (@inoue_mokei) <a href="https://twitter.com/inoue_mokei/status/1255331585563545600?ref_src=twsrc%5Etfw">April 29, 2020</a></blockquote>
            <blockquote className="twitter-tweet" data-conversation="none"><p lang="ja" dir="ltr">デート[5/5] <a href="https://twitter.com/hashtag/%E7%8A%AC?src=hash&amp;ref_src=twsrc%5Etfw">#犬</a> <a href="https://twitter.com/hashtag/C98?src=hash&amp;ref_src=twsrc%5Etfw">#C98</a> <a href="https://twitter.com/hashtag/%E3%82%A8%E3%82%A2%E3%82%B3%E3%83%9F%E3%82%B1?src=hash&amp;ref_src=twsrc%5Etfw">#エアコミケ</a> <a href="https://twitter.com/hashtag/%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB?src=hash&amp;ref_src=twsrc%5Etfw">#サークル</a> <a href="https://t.co/kiT5A27Ic0">pic.twitter.com/kiT5A27Ic0</a></p>&mdash; イノウエ模型@C99木曜東マ12b (@inoue_mokei) <a href="https://twitter.com/inoue_mokei/status/1255331801163325448?ref_src=twsrc%5Etfw">April 29, 2020</a></blockquote>
          </div>
          <div className="share">
            <TwitterShareButton title="デート | 道具小路×イノウエ模型" via="inoue_mokei" url={articleUrl}>
              <TwitterIcon size={32} round/>
            </TwitterShareButton>
            <FacebookShareButton url={articleUrl}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
        </div>
      </div>
    )};

  return (
    <div>
      <HeaderWithoutBanner />
      <div id="main">
        <div className="container">
          <section>
            <header className="news-head">
              <span className="icon-HP-">
                <span className="path3" />
              </span>
              <span>Products</span>
            </header>
          </section>

          <MediaQuery minDeviceWidth={481}>
            <ProductsMain isFlex={true}>
              {productImage()}
              {productText()}
            </ProductsMain>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={480}>
            <ProductsMain isFlex={false}>
              {productImage()}
              {productText()}
            </ProductsMain>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

export default Date;
