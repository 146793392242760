import React from 'react';
import HeaderWithBanner from '../organisms/HeaderWithBanner';
import TopNews from '../organisms/TopNews';
import About from '../organisms/About';
import ReactGA from "react-ga4";

const Top: React.FC = () => {
  ReactGA.initialize("G-XBRWCGVCPT");
  ReactGA.send("pageview");
  return (
    <div>
      <HeaderWithBanner />
      <TopNews />
      <About />
    </div>
  );
}

export default Top;
