import React,{useState} from 'react';
import MobileMenu from '../pages/MobileMenu';
import AnchorLink from '../../presentational/atoms/AnchorLink';
import {HashLink as Link} from 'react-router-hash-link';
import MediaQuery from 'react-responsive'

interface IHeaderProps{
	isTop:boolean;
}

const Header = (props:IHeaderProps) => {
	const [isShowMobileMenu, setMobileMenu] = useState(false);
  return (
			<div id="header">
				<div className="container">
						<div id="logo">
              				<a href="/"><img src="/img/logo/logo_plane.png" alt="イノウエ模型ロゴ"/></a>
						</div>
						<MediaQuery minDeviceWidth={481} >
							<nav id="nav">
								<ul>
									<li><Link to="/">Top</Link></li>
									<li>{props.isTop ? <AnchorLink href="#main">News</AnchorLink> : <Link to="/#main">News</Link>}</li>
									<li>{props.isTop ? <AnchorLink href="#about">About</AnchorLink> : <Link to="/#about">About</Link>}</li>
									<li><Link to="/products">Products</Link></li>
								</ul>
							</nav>
						</MediaQuery>
						<MediaQuery maxDeviceWidth={480}>
							<button className="hamburger" onClick={() => setMobileMenu(!isShowMobileMenu)}>
								<img src="/img/assets/menu.svg" alt="メニューボタン"/>
							</button>
								{
									isShowMobileMenu ? <MobileMenu isTop={props.isTop} closeAction={setMobileMenu}/> : null
								}
						</MediaQuery>
				</div>
			</div>
  );
}

export default Header;
