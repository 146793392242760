import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import ReactGA from "react-ga4";

interface IMobileMenuProps{
  isTop:boolean,
  closeAction:React.Dispatch<React.SetStateAction<boolean>>
}
const MobileMenu = (props: IMobileMenuProps) => {
  ReactGA.initialize("G-XBRWCGVCPT");
  ReactGA.send("pageview");
  return (
    <div id="mobile-menu">
      <div id="header">
				<div className="container">
						<div id="logo">
              <a href="/"><img src="/img/logo/logo_plane.png" alt="イノウエ模型ロゴ"/></a>
						</div>
            <button className="hamburger" onClick={() => props.closeAction(false)}>
              <img src="/img/assets/close.svg" alt="閉じる"/>
            </button>
				</div>
			</div>
      <ul>
        <li>{props.isTop ? <a href="/#" onClick={() => props.closeAction(false)}>Top</a> : <Link to="/">Top</Link>}</li>
        <li>{props.isTop ? <a href="#main" onClick={() => props.closeAction(false)}>News</a> : <Link smooth to="/#main">News</Link>}</li>
        <li>{props.isTop ? <a href="#about" onClick={() => props.closeAction(false)}>About</a> : <Link smooth to="/#about">About</Link>}</li>
        <li>{props.isTop ? <Link to="/products">Products</Link> : <a href="/#" onClick={() => props.closeAction(false)}>Products</a>}</li>
      </ul>
    </div>
  );
}

export default MobileMenu;
