import React from 'react';
import './App.css';
import {BrowserRouter,Route} from 'react-router-dom';

import Top from './container/pages/Top';
import Products from './container/pages/Products';
import ZakuToNaisho from './container/pages/product/ZakuToNaisho';
import WorldMobileSuitMuseum from './container/pages/product/WorldMobileSuitMuseum';
import Date from './container/pages/product/Date';
import WorldMobileSuitMuseum2 from './container/pages/product/WorldMobileSuitMuseum2';
import c99 from './container/pages/omake/c99';
import Footer from './container/organisms/Footer';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Route exact path="/" component={Top} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/products/zakutonaisho" component={ZakuToNaisho} />
        <Route exact path="/products/worldmobilesuitmuseum" component={WorldMobileSuitMuseum} />
        <Route exact path="/products/date" component={Date} />
        <Route exact path="/products/worldmobilesuitmuseum2" component={WorldMobileSuitMuseum2} />
        <Route exact path="/omake/c99" component={c99} />
      </div>
      <Footer />
      <div style={{backgroundColor:"#f3f3f3",width:"100%",textAlign:"center"}}>
				<span style={{fontSize:10}}>© 2019 イノウエ模型</span>
			</div>
    </BrowserRouter>
  );
}

export default App;
