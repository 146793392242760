import React from "react";
import HeaderWithoutBanner from "../../organisms/HeaderWithoutBanner";
import ImageGallery from 'react-image-gallery';
import ReactGA from "react-ga4";

const Date: React.FC = () => {
  React.useEffect(()=>{
    // scriptを読み込み
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(script);
    // アンマウント時に一応scriptタグを消しておく
    return () => {
        document.body.removeChild(script);
    }
  }, [])
  ReactGA.initialize("G-XBRWCGVCPT");
  ReactGA.send("pageview");
  return (
    <div>
      <HeaderWithoutBanner />
      <div id="main">
        <div className="container">
          <section>
            <p>手に取っていただきありがとうございました。</p>
            <p>気に入っていただけましたか？</p>
            <p>よかったらTwitterのフォローよろしくお願いします！</p>
            <a href="https://twitter.com/inoue_mokei?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @inoue_mokei</a>
          </section>
          <section>
            <ImageGallery items={images} showFullscreenButton={false} autoPlay slideInterval={8000}/>
            <p>感想のツイートをいただけると泣いて喜びます。</p>
          </section>
        </div>
      </div>
    </div>
  );
};

const images = [
  {
    original: "/img/products/c99/omake1.jpg",
    thumbnail: '/img/products/c99/omake1_150.jpg',
  },
  {
    original: "/img/products/c99/omake2.jpg",
    thumbnail: '/img/products/c99/omake2_150.jpg',
  },
]

export default Date;
